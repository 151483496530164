import bodymovin from 'lottie-web';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);



function createLottie(fileName, containerId, speed = 1) {
    const animation = bodymovin.loadAnimation({
        // animationData: { /* ... */ },
        container: document.querySelector(`${containerId}`),
        path: `${php_vars.themeDirUrl}/assets/lottie/${fileName}.json`,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        name: fileName,
    });

    window.addEventListener('load', () => {
        animation.setSpeed(speed);
        animation.play();
    });
    
    return animation;
}

function Lottie() {
    const newsletterSanta = createLottie('santa', '#santa', 0.25);
    const newsletterSnowAngel = createLottie('snow-angel', '#snow-angel');
    const newsletterSnowman = createLottie('snowman', '#snowman');
    const newsletterWreath = createLottie('wreath', '#wreath');
}

export default Lottie;